<template>
  <div style="padding: 20px">
    <div class="handle-box">
      <select-area @areaListId="getAreaListId"/>
      <el-input v-model="query.phone" placeholder="手机号" class="handle-input mr10"></el-input>

      <el-date-picker style="width: 260px" v-model="v1" type="daterange" range-separator="至"
                      start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <el-select v-model="query.state" placeholder="审核状态" class="handle-select mr10">
        <el-option key="开启" label="开启" value="开启"></el-option>
        <el-option key="关闭" label="关闭" value="关闭"></el-option>
      </el-select>
      <el-button type="primary" @click="handleSearch">搜索</el-button>
      <el-button type="info" @click="reset">重置</el-button>
      <el-popconfirm @confirm="exportExcel"
                     title="确定要导出吗？"
      >
        <template #reference>
          <el-button type="primary">导出</el-button>
        </template>
      </el-popconfirm>
    </div>

    <!--数据列-->
    <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        :row-style="{height:10+'px'}"
        :cell-style="{padding:5+'px'}"
        header-cell-class-name="table-header"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
      <el-table-column label="所属(区，县)">
        <template #default="scope">{{ scope.row.areaName !== "" ? scope.row.areaName : '' }}</template>
      </el-table-column>
      <el-table-column label="所属校区">
        <template #default="scope">{{ scope.row.campusName !== "" ? scope.row.campusName : '' }}</template>
      </el-table-column>
      <el-table-column prop="manageName" label="管理人"></el-table-column>
      <el-table-column label="审核人">
        <template #default="scope">{{ scope.row.auditName !== "" ? scope.row.auditName : '' }}</template>
      </el-table-column>
      <el-table-column prop="auditTime" label="审核时间" width="170"></el-table-column>
      <el-table-column label="用户手机号">
        <template #default="scope">
          <div style="display: flex">
            <div style="width: 100px">
              <span v-if="scope.row.phone!=notEncryptId">{{ filterTel(scope.row.phone) }}</span>
              <span v-else>{{ scope.row.phone }}</span>
            </div>
            <div>
            <span @click="viewPhone(scope.row.phone)">
              <svg v-if="scope.row.phone!=notEncryptId" t="1644395285994" style="width: 20px;height: 20px"
                   class="icon"
                   viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="2629" data-spm-anchor-id="a313x.7781069.0.i16" width="200"
                   height="200">
                <path
                    d="M660.8 680.4c-10.1 0-20-5.2-25.5-14.4l-59.4-99.1c-8.4-14.1-3.9-32.3 10.2-40.8 14.1-8.4 32.3-3.8 40.8 10.2l59.4 99.1c8.4 14.1 3.9 32.3-10.2 40.8-4.8 2.9-10.1 4.2-15.3 4.2zM819.1 581.3c-7.6 0-15.2-2.9-21-8.7l-79.2-79.3c-11.6-11.6-11.6-30.4 0-42 11.6-11.6 30.4-11.6 42 0l79.2 79.3c11.6 11.6 11.6 30.4 0 42-5.8 5.9-13.4 8.7-21 8.7zM363.2 680.4c-5.2 0-10.5-1.3-15.3-4.2-14.1-8.4-18.6-26.7-10.2-40.8l59.4-99.1c8.4-14.1 26.7-18.7 40.8-10.2 14.1 8.4 18.6 26.7 10.2 40.8L388.7 666c-5.6 9.3-15.4 14.4-25.5 14.4zM204.9 581.3c-7.6 0-15.2-2.9-21-8.7-11.6-11.6-11.6-30.4 0-42l79.2-79.3c11.6-11.6 30.4-11.6 42 0 11.6 11.6 11.6 30.4 0 42l-79.2 79.3c-5.8 5.9-13.4 8.7-21 8.7z"
                    p-id="2630"></path>
                <path
                    d="M521.6 561.5c-82.8 0-204.7-28.4-314.9-163.5-1.3-1.6-2.3-2.8-3-3.6-10.9-10.8-11.8-28.4-1.7-40.3 10.6-12.5 29.4-14.1 41.9-3.5 1.3 1.1 4.4 4.3 8.9 9.8 95.5 117.1 198.9 141.7 268.8 141.7 120.7 0 258.1-124.6 271.6-144.4 9.1-13.2 27.3-17.5 40.9-8.8 13.5 8.6 18.1 25.8 10 39.6-14.1 23.7-168.8 173-322.5 173z"
                    p-id="2631"></path>
              </svg>
              <svg v-else t="1644397194443" style="width: 20px;height: 20px" class="icon" viewBox="0 0 1024 1024"
                   version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="2770" width="200" height="200"><path
                  d="M512 832c-156.448 0-296.021333-98.730667-418.410667-291.605333a52.938667 52.938667 0 0 1 0-56.789334C215.978667 290.730667 355.552 192 512 192c156.448 0 296.021333 98.730667 418.410667 291.605333a52.938667 52.938667 0 0 1 0 56.789334C808.021333 733.269333 668.448 832 512 832z m0-576c-129.514667 0-249.461333 83.850667-360.117333 256C262.538667 684.149333 382.485333 768 512 768c129.514667 0 249.461333-83.850667 360.117333-256C761.461333 339.850667 641.514667 256 512 256z m0 405.333333c-83.210667 0-150.666667-66.858667-150.666667-149.333333S428.789333 362.666667 512 362.666667s150.666667 66.858667 150.666667 149.333333S595.210667 661.333333 512 661.333333z m0-64c47.552 0 86.101333-38.208 86.101333-85.333333S559.552 426.666667 512 426.666667c-47.552 0-86.101333 38.208-86.101333 85.333333s38.549333 85.333333 86.101333 85.333333z"
                  p-id="2771"></path></svg>
            </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="添加时间" width="170"></el-table-column>
      <el-table-column
          label="审核状态"
          width="110">
        <template #default="scope">
          <el-tooltip :content="scope.row.state==='开启'? '关闭后此用户将无法使用':'确定要开启吗'" placement="top">
            <el-switch
                @click="setState(scope.row.id,scope.row.state)"
                v-model="scope.row.state"
                active-value="开启"
                inactive-value="关闭"
            >
            </el-switch>
          </el-tooltip>
        </template>
      </el-table-column>


      <el-table-column label="操作" width="240" align="center">
        <template #default="scope">
          <el-button
              type="text"
              @click="handleEdit(scope.$index, scope.row)"
          >编辑
          </el-button>
          <el-button
              type="text"
              icon="el-icon-delete"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
          >删除
          </el-button>
          <el-button
              type="text"
              @click="details(scope.$index, scope.row)"
          >查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="修改信息" v-model="editVisible" width="900px">
      <el-form :model="form" :rules="rules" ref="editForm" label-width="120px" class="demo-ruleForm">
        <el-row>
          <el-col :span="8">
            <el-form-item class="itemWih" label="学员电话" prop="studentPhone">
              <el-input v-model="form.phone" oninput="value=value.replace(/[^\d.]/g, '')">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item prop="regionId" label="所属城市">
              <el-select v-model="form.regionId" placeholder="城市"
                         @change="selectCity2">
                <el-option v-for="(item,index) in regionList" :key="index" :label="item.region"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="campusId" label="所属校区">
              <el-select v-model="form.campusId" placeholder="校区">
                <el-option style="width: 160px" v-for="(item,index) in campusList1" :key="index"
                           :label="item.campusname" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item prop="manageId" label="校区负责人人">
              <el-select
                  v-model="form.manageName"
                  filterable
                  remote
                  placeholder="需要添加到此课程的学员姓名"
                  :remote-method="remoteMethod"
              >
                <el-option
                    v-for="(item,index) in teacherList"
                    :key="index"
                    :label="item.coachname"
                    :value="index"
                    @click="choiceTeacher(item.coachid)">
                  <span style="float: left">{{ item.coachname }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
              <span class="dialog-footer">
                  <el-button @click="qx">取 消</el-button>
                  <el-button type="primary" @click="saveEdit('editForm')">确 定</el-button>
              </span>
      </template>
    </el-dialog>
    <!--详情-->
    <el-dialog
        :title="title"
        v-model="selectDetails"
        width="1109px"
        center :before-close="handleClose">
      <div style="height: 500px">
          <el-container style="height: 480px; border: 1px solid #ee5f23">
              <el-main>
        <el-tabs v-model="activeName" @tab-click="handleClick" stretch="true">
          <el-tab-pane label="正卡" name="first">
            <el-table :data="orderList" border
                      class="table"
                      ref="multipleTable"
                      :row-style="{height:10+'px'}"
                      :cell-style="{padding:8+'px'}"
                      header-cell-class-name="table-header">
                <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
              <el-table-column prop="studentName" label="姓名" width="80">
              </el-table-column>
              <el-table-column prop="phone" label="手机号" width="80" show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="remaining" label="剩余课时" width="80">
              </el-table-column>
              <el-table-column prop="ouse" label="已上课时" width="80">
              </el-table-column>
              <el-table-column prop="regionName" label="城市" width="80">
              </el-table-column>
              <el-table-column prop="campusName" label="校区" show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="coachName" label="教练" width="80">
              </el-table-column>
              <el-table-column prop="cardName" label="课程类型" show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="state" label="状态" width="80">
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="110" show-overflow-tooltip="true">
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="副卡" name="second">
            <div v-if="sendOrdersList.length>0">
            <el-table :data="sendOrdersList" border
                      class="table"
                      ref="multipleTable"
                      :row-style="{height:10+'px'}"
                      :cell-style="{padding:7+'px'}"
                      header-cell-class-name="table-header">
              <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
              <el-table-column prop="cardName" label="所属主卡" width="100" show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="sendCardName" label="副卡" width="100" show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="remaining" label="剩余课时" width="80">
              </el-table-column>
              <el-table-column prop="suse" label="已上课时" width="80">
              </el-table-column>
              <el-table-column prop="regionName" label="城市" width="80">
              </el-table-column>
              <el-table-column prop="campusName" label="校区" show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column prop="coachName" label="教练" width="80">
              </el-table-column>
              <el-table-column prop="teacherName" label="上课老师" width="80">
              </el-table-column>
              <el-table-column prop="state" label="状态" width="80">
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="110" show-overflow-tooltip="true">
              </el-table-column>
            </el-table>
            </div>
            <div v-else>
              <span>暂无赠卡</span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="角色管理" name="third">敬请期待</el-tab-pane>
          <el-tab-pane label="定时任务补偿" name="fourth">敬请期待</el-tab-pane>

        </el-tabs>
              </el-main>
          </el-container>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {userPage, updateState, deleteUser, updateUser, excel, selectOrders, excelProportion,selectSendOrders} from "@/api/user";
import {coachByNameOrPhone} from "@/api/coach";
import selectArea from "../../components/SelectArea";

export default {
  components: {
    selectArea
  },
  name: "user",
  data() {
    return {
      notEncryptId: '',
      query: {
        phone: "",
        areaList:[],
        startDate: '',
        endDate: '',
        pageIndex: 1,
        pageSize: 10,
        state: ''
      },
      activeName: 'first',
      v1: [],
      tableData: [],
      regionList: [],
      campusList: [],
      campusList1: [],
      editVisible: false,
      addForm: {},
      form: {},
      teacherList: [],
      startDateAndEndDate: null,
      selectDetails: false,
      orderList: [],
      sendOrdersList:[],
      title:null,
      rules: {
        phone: [{required: true, message: '请输入手机号', trigger: 'blur'},],
        regionId: [{required: true, message: '请添加城市', trigger: 'blur'},],
        campusId: [{required: true, message: '请选择校区', trigger: 'blur'},],
        manageId: [{required: true, message: '请选择管理人', trigger: 'blur'},]
      },
    }
  }, methods: {

    viewPhone(val) {
      if (val == this.notEncryptId) {
        this.notEncryptId = "";
      } else {
        this.notEncryptId = val;
      }
    },
    filterTel(val) {
      //加密手机号
      if (val) {
        return (val.substring(3, 0)) + '****' + (val.substring(7));
      }
    },
    // 获取数据
    getData() {
      // 表格数据
      userPage(this.query).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.pageTotal = res.data.total;
        } else {
          this.$message.error("获取表格数据失败");
        }
      }).catch(() => {
      })
    },    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    },
    details(index, val) {
      this.title=val.phone;
      this.activeName='first';
      this.selectDetails = true;
      //主卡
      selectOrders(val.id).then(res => {
        if (res.code === 200) {
          console.log(res.data);
          this.orderList = res.data;
        } else {
          this.$message.error("获取表格数据失败");
        }
      });
      //副卡
      selectSendOrders(val.id).then(res => {
        if (res.code === 200) {
          this.sendOrdersList = res.data;
        } else {
          this.$message.error("获取表格数据失败");
        }
      });
    },
    getAreaListId(val) {
      this.query.areaList = val;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 编辑操作
    de(index, row) {
      // 校区数据
      JSON.parse(JSON.stringify(this.regionList)).map(r => {
        if (r.id == row.regionId) {
          this.campusList1 = r.campuses
        }
      })
      this.idx = index;
      this.form = row;
      console.log(this.form)
      this.editVisible = true;
    },
    //编辑修改上课教练
    remoteMethod(query) {
      console.log(this.form)
      console.log(query)
      if (query !== '') {
        coachByNameOrPhone(query, query).then(res => {
          if (res.code === 200) {
            this.teacherList = res.data
          } else {
            this.teacherList = [];
          }
        })
      } else {
        this.teacherList = [];
      }
    },
    //取消编辑
    qx() {
      this.editVisible = false;
      this.getData();
      this.$message.info(`您取消了修改`);
    },
    // 编辑 选中城市  渲染校区
    selectCity2(val) {
      this.form.campusId = null
      this.campusList1 = JSON.parse(JSON.stringify(this.regionList[val - 1])).campuses;
    },
    choiceTeacher(val) {
      this.form.manageId = val;
    },
    // 修改状态
    setState(id, state) {
      updateState(id, state).then(res => {
        if (res.code === 200) {
          this.$message.success(`操作成功`);
        } else {
          this.$message.error(`操作失败`);
        }
      })
    },
    // 触发搜索按钮
    handleSearch() {
      if (this.v1.length >= 2) {
        this.query.startDate = this.dateFormat(this.v1[0]);
        this.query.endDate = this.dateFormat(this.v1[1]);
      }
      this.query.pageIndex = 1
      this.getData();
    },

    // 保存编辑
    saveEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updateUser(JSON.parse(JSON.stringify(this.form))).then(res => {
            this.editVisible = false;
            if (res.code == 200) {
              if (res.success) {
                this.$message.success(`修改用户: ${this.form.phone} 成功`);
              }
            } else {
              this.$message.error(`修改城市: ${this.form.phone} 失败`);
            }
            this.getData()
          })
        } else {
          return false;
        }
      })
    },
    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除用户: " + row.phone + " 吗?", "提示", {
        type: "warning"
      }).then(() => {
        deleteUser(row.id).then(res => {
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.getData();
          } else {
            this.$message.error("删除失败");
          }
        })
      }).catch(() => {
      });
    },
    //导出
    exportExcel() {
      this.loading = true
      if (this.startDateAndEndDate) {
        this.query.startDate = this.dateFormat(this.startDateAndEndDate[0])
        this.query.endDate = this.dateFormat(this.startDateAndEndDate[1])
      }
      excel(this.query).then(res => {
        console.log(res)
        this.loading = false
        let blob = new Blob([res], {type: "application/vnd.ms-excel"});
        console.log(blob);
        let objectUrl = URL.createObjectURL(blob);
        console.log(objectUrl);
        const link = document.createElement('a');//我们用模拟q标签点击事件
        const fname = '用户审核表'; //下载文件的名字
        link.href = objectUrl;
        link.setAttribute('download', fname);
        document.body.appendChild(link);
        /**
         * 在这期间，我们可以做自己界面的反应逻辑
         **/
        link.click();//点击
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(URL); // 释放掉blob对象
      })
    },
    excelProportion() {
      this.loading = true
      if (this.startDateAndEndDate) {
        this.query.startDate = this.dateFormat(this.startDateAndEndDate[0])
        this.query.endDate = this.dateFormat(this.startDateAndEndDate[1])
      }
      excelProportion(this.query).then(res => {
        console.log(res)
        this.loading = false
        let blob = new Blob([res], {type: "application/vnd.ms-excel"});
        console.log(blob);
        let objectUrl = URL.createObjectURL(blob);
        console.log(objectUrl);
        const link = document.createElement('a');//我们用模拟q标签点击事件
        const fname = '用户审核表'; //下载文件的名字
        link.href = objectUrl;
        link.setAttribute('download', fname);
        document.body.appendChild(link);
        /**
         * 在这期间，我们可以做自己界面的反应逻辑
         **/
        link.click();//点击
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(URL); // 释放掉blob对象
      })
    },
    //重置
    reset() {
      this.query.areaList=[]
      this.query.phone = ""
      this.query.state = ""
      // 日期
      this.v1 = []
      this.query.startDate = ""
      this.query.endDate = ""
      this.query.pageIndex = 1
      this.query.pageSize = 10
      this.v1 = []
      this.getData()
    },
    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    },
  },
  activated(){ 
    console.log('aaa');
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
}

.el-aside {
    color: #333;
}
</style>