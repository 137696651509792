import request from "../utils/request";


/**
 * 修改状态
 * @param id
 * @param state
 * @returns {AxiosPromise}
 */
export const updateState = (id, state) => {
    return request({
        url: '/sys/user/updateState',
        method: 'get',
        params: {
            id,
            state
        }
    });
};
export const deleteUser = (id) => {
    return request({
        url: '/sys/user/delete',
        method: 'get',
        params: {
            id,
        }
    });
};
//修改用户
export const updateUser = (user) => {
    return request({
        url: '/sys/user/updateUser',
        method: 'POST',
        data: user
    });
};

/**
 * 获取用户列表
 * @param query
 * @returns {AxiosPromise}
 */
export const userPage = (query) => {
    return request({
        url: '/sys/user/userPage',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 * 导出
 * @param query
 * @returns {AxiosPromise}
 */
export const excel = (query) => {
    return request({
        url: '/sys/user/excel',
        method: 'get',
        params: query,
        responseType: 'arraybuffer'
    });
};

export const selectOrders = (userId) => {
    return request({
        url: '/sys/user/details/selectOrders',
        method: 'get',
        params: {userId},
    });
};
export const selectSendOrders = (userId) => {
    return request({
        url: '/sys/user/details/selectSendOrders',
        method: 'get',
        params: {userId},
    });
};
/**
 *
 * @param query
 * @returns {AxiosPromise}
 */
export const excelProportion = (query) => {
    return request({
        url: '/sys/user/excelProportion',
        method: 'get',
        params: query,
        responseType: 'arraybuffer'
    });
};